import type {
  FormSettingsBase,
  BaseFormCompanySettings,
} from 'form-definition';
import { useMemo } from 'react';
import styled from 'styled-components';
import { generateImageSrcs } from '../utils';

const StyledImage = styled.img`
  max-width: min(400px, 100%);
  max-height: 170px;
  object-fit: scale-down;
  object-position: left;
  margin-bottom: ${({ theme }) => theme.space.x4};
`;

interface CompanyLogoProps {
  settings: FormSettingsBase;
  companySettings: BaseFormCompanySettings;
}

export const CompanyLogo = ({
  settings,
  companySettings,
}: CompanyLogoProps) => {
  const logoUrl =
    settings.useCustomBranding && settings.customBrandLogoUrl
      ? settings.customBrandLogoUrl
      : companySettings.companyLogoUrl;
  const name =
    settings.useCustomBranding && settings.customBrandName
      ? settings.customBrandName
      : companySettings.companyName;

  const { src, srcSet } = useMemo(
    () =>
      generateImageSrcs({
        url: logoUrl,
        width: 400,
        height: 170,
        upscale: true,
      }),
    [logoUrl],
  );

  if (!logoUrl) {
    return null;
  }

  return <StyledImage src={src} srcSet={srcSet} alt={name ?? ''} />;
};
